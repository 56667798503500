import { Injectable } from '@angular/core';
import { ClientOptions } from '../model/ClientOptions';
import { Veiculo } from '../model/Veiculo';
import { DirectionOptionEnum } from '../model/DirectionOptionEnum';
import { Local } from '../model/Local';

declare var L: any;

const METERS_DISTANCE_TO_COMPARE_VELOCIDADE:number = 50;

@Injectable({
  providedIn: 'root'
})

export class TimeGeoService {

  constructor() { }

  
  getTempoEstimadoByKml(veiculo:Veiculo, clientOptions: ClientOptions, jsonKml: any, lerDoKml: boolean): string {

    let directions = this.getArrayOfPoints(jsonKml);
    switch(veiculo.getDirection(clientOptions))
    {
        // destrinchar o kml para obter as distancias armazenadas [ok]
        // obter distancia entre posição atual e <sede> 
        // obter distancia percorrida anterior entre 2 pontos que tenham distancia mínima para calculo
        // obter diferença tempo entre estes 2 pontos
        // estimar velocidade
        // estimar tempo que falta ate a <sede>
        case DirectionOptionEnum.CheckIn:
        case DirectionOptionEnum.CheckOut:
            var rs = directions.getTempoEstimado(veiculo, lerDoKml);

            if (isNaN(rs) || !isFinite(rs)){
              return 'Cheguei';
            }
            else {
              // para cenário de leitura do tempo do kml onde o tempo é menor que 1 minuto
              if (rs <= 0.5) 
                return 'Chegando';
              else
                return rs.toFixed(0) + ' min...';
            }
        case DirectionOptionEnum.ChegandoAeroporto:
        case DirectionOptionEnum.ChegandoLocaliza:
            return 'Chegando';
        case DirectionOptionEnum.ChegouAeroporto:
        case DirectionOptionEnum.ChegouLocaliza:
            return 'Cheguei';
        default:
            return 'n/d';
    }    
  }  

  getArrayOfPoints(jsonKml:any):Directions{

    let locais = new Directions();

    jsonKml.kml.Document.Folder.forEach(element => {
      // Folder class
      
      switch (element["@attributes"].id)
        {
          case "Waypoints" : break;
          case "Tracks": 
            //console.log(element.Folder.TotalKm);

            locais.TotalKm = element.Folder.TotalKm;

            if (element.Folder.Folder.Placemark instanceof Array){
              element.Folder.Folder.Placemark.forEach(placemark => {
                //console.log(placemark.Km);
                //console.log(placemark.Point.coordinates);

                locais.Locais.push(new LocalWithDistance(placemark.Km, placemark.Point.coordinates, placemark.FaltamDuration));
              });
            } else if (element.Folder.Folder.Placemark) {
              locais.Locais.push(new LocalWithDistance(element.Folder.Folder.Placemark.Km, element.Folder.Folder.Placemark.Point.coordinates, element.Folder.Folder.Placemark.FaltamDuration));
            }
            
          break;
        }
    });

    return locais;
  }
}


export class Directions {

  constructor(){
    this.Locais = [];
    this.TotalKm = 0;
  }

  public Locais : LocalWithDistance[];
  public TotalKm : number;

  public getTempoEstimado(veiculo: Veiculo, lerDoKml: boolean): number {

        // obter distancia entre posição atual e <sede> 
        var posTeste = veiculo.getLastPosition();
        var nextPointBase = this.getNextPoint(posTeste.getLocal());
        var distancia_a_estimar = this.TotalKm - nextPointBase.distanceFromBase;

        var tempo_estimado = nextPointBase.faltamDuration;

        if (lerDoKml) {
          console.log('Tempo estimado kml :' + tempo_estimado + 's');
          return tempo_estimado / 60;
        }

        var somaVelocidade = 0; //km/h
        var qtdAmostras = 0;

        // obter distancia percorrida anterior entre 2 pontos que tenham distancia mínima para calculo
        veiculo.getPosicoesDescendingOrder().forEach(element => {

          var nextPointEl = this.getNextPoint(element.getLocal());
          var distance = Math.abs(nextPointBase.distanceFromBase - nextPointEl.distanceFromBase);

        // obter diferença tempo entre estes 2 pontos
        // estimar velocidade
        if (distance > (METERS_DISTANCE_TO_COMPARE_VELOCIDADE/1000)){
             var ms = posTeste.data.getTime() - element.data.getTime();

             var vax = (distance / ms) * 3600000;

             if (vax == 0)
                return;

             somaVelocidade += vax;
             qtdAmostras++;

            //  if (velocidade == 0){
            //   velocidade = vax;
            //  } else {
            //    // pegando a média de 2 pontos para melhorar a acurácia
            //    velocidade = (vax + velocidade)/2; 
            //    return;
            //  }
          } 
        });
        
        let velocidade = somaVelocidade / qtdAmostras;

        console.log('Velocidade estimada em ' + velocidade + ' baseado em ' + qtdAmostras + ' amostras.');

        // estimar tempo que falta ate a <sede> em minutos
        var tempo = distancia_a_estimar/velocidade; // tempo em horas

        return tempo * 60;
  }

  public getNextPoint(local:Local){

      let currentLocal:LocalWithDistance = null;
      let currentDistante = 1000000000;

      this.Locais.forEach(element => {
      
        var distance = L.GeometryUtil.length([
          L.latLng(element.Latitude,element.Longitude),
          L.latLng(local.Latitude, local.Longitude)]);
          
          if(distance < currentDistante){
            currentDistante = distance;
            currentLocal = element;
          }

      });

      return currentLocal;
  }
}

export class LocalWithDistance extends Local {
  
  constructor(km: number, lonlatzoomcoordinates:string, faltamDuration: number) {
    super();
    this.distanceFromBase = km;
    this.faltamDuration = faltamDuration;
    this.Latitude = Number.parseFloat(lonlatzoomcoordinates.split(',')[1]);
    this.Longitude = Number.parseFloat(lonlatzoomcoordinates.split(',')[0]);
  }
  public distanceFromBase: number;
  public faltamDuration: number;
}
