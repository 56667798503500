import { formatDate, DatePipe } from "@angular/common";
import { Local } from "./Local";

export class Posicao {
  latitude: number;
  longitude: number;
  data: Date;

  constructor(data: string, lat: number, lon: number) {
    let auxData = new Date(data);
    this.data = new Date(
      Date.UTC(
        auxData.getFullYear(),
        auxData.getMonth(),
        auxData.getDate(),
        auxData.getHours(),
        auxData.getMinutes(),
        auxData.getSeconds()
      )
    );
    this.latitude = lat;
    this.longitude = lon;
  }

  getLocal() {
    var local = new Local();
    local.Latitude = this.latitude;
    local.Longitude = this.longitude;
    return local;
  }

  getDateFormated() {
    return this.data.toLocaleDateString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
    // return this.data.getDay() + "/"
    //     + (this.data.getMonth() + 1) + "/" // base 0
    //     + this.data.getFullYear() + " "
    //     + this.data.getHours() + ":"
    //     + this.data.getMinutes() + ":"
    //     + this.data.getSeconds();
  }
}
