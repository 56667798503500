import { DirectionEnum } from "./DirectionOptionEnum";
import { Local } from "./Local";

export class CercaInfo {
  posicoes: Array<Local>;
  public Sentido: DirectionEnum;

  nomeCerca: string;

  constructor(public descricao: string, public type: string, sentido: number) {
    this.nomeCerca = descricao;
    this.posicoes = new Array<Local>();

    switch (sentido) {
      case 0:
        this.Sentido = DirectionEnum.CheckOut;
        return;
      case 1:
        this.Sentido = DirectionEnum.CheckIn;
        return;
      default:
        this.Sentido = DirectionEnum.Unknow;
        return;
    }
  }

  ehCercaAreaUtil(): boolean {
    return (
      this.nomeCerca.includes("Área Útil") ||
      this.nomeCerca.includes("Area Util") ||
      this.nomeCerca.includes("Área Util") ||
      this.nomeCerca.includes("Area Útil") ||
      this.nomeCerca.includes("Manutenção")
    );
  }

  ehCercaEmbarque(): boolean {
    return (
      this.nomeCerca.includes("Embarque") &&
      !this.nomeCerca.includes("Desembarque")
    );
  }

  ehCercaDesembarque(): boolean {
    return this.nomeCerca.includes("Desembarque");
  }

  estaDentro(latitude: number, longitude: number) {
    // ray-casting algorithm based on
    // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html

    var inside = false;
    for (
      var i = 0, j = this.posicoes.length - 1;
      i < this.posicoes.length;
      j = i++
    ) {
      var xi = this.posicoes[i].Latitude,
        yi = this.posicoes[i].Longitude;
      var xj = this.posicoes[j].Latitude,
        yj = this.posicoes[j].Longitude;

      var intersect =
        yi > longitude != yj > longitude &&
        latitude < ((xj - xi) * (longitude - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }

    return inside;
  }
}
