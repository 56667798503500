import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChooseAirportComponent } from './choose-airport/choose-airport.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import { MapaVeiculosComponent } from './mapa-veiculos/mapa-veiculos.component';
import { HttpClientModule } from '@angular/common/http';

import { NgpSortModule } from "ngp-sort-pipe";
import { FeedbackComponent } from './feedback/feedback.component';
import { FormsModule } from '@angular/forms';
import { CallVanComponent } from './call-van/call-van.component';

@NgModule({
  declarations: [
    AppComponent,
    ChooseAirportComponent,
    MapaVeiculosComponent,
    FeedbackComponent,
    CallVanComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCardModule,
    HttpClientModule,
    NgpSortModule,
    FormsModule
  ],
  providers: [
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
