import { DirectionOptionEnum } from "./DirectionOptionEnum";
import { Airport } from "./Airport";
import { Local } from "./Local";

export class ClientOptions {
  Airport: Airport;
  Direcion: DirectionOptionEnum;
  FixedAirport?: Boolean;

  constructor(init?: Partial<ClientOptions>) {
    Object.assign(this, init);
  }
}
