<div class="background">
    <div class="card">
        <div class="container">
            <div class="logo-container">
                <img class="logo" title="logo" src="assets/images/localiza.svg" />
            </div>
            <span class="title" style="font-size: 15px; color: #616161;">Bem vindo:</span>
            <br>
            <span class="info" style="color: #00974a; font-size: 20px; font-weight: bold;">Passageiro</span>
            <p>Escolha o seu Aeroporto.</p>
        </div>
    </div>
    <div class="container">
        <div *ngIf="!airportSelected()" class="row">
            <select id="aeroporto" title="Selecione o Aeroporto" class="button-estados control select" (change)="escolherAeroportoHandler($event)">
                <option selected disabled hidden>Escolha uma opção</option>
                <option *ngFor="let airport of _airports | sortBy: 'asc' : 'Name'" [value]="airport.Sigla"
                    [attr.selected]="airport.Name==currentSelectedAirportName() ? true : null">
                    {{airport.Name}}</option>
            </select>
        </div>
        <div *ngIf="!airportSelected() && getQtdTerminais() > 0 " class="row">
          <select id="terminal" title="Selecione o Terminal" class="button-estados control select" (change)="escolherTerminalHandler($event)">
              <option selected disabled hidden>Escolha uma opção</option>
              <option *ngFor="let terminal of currentAirport?.Terminais | sortBy: 'asc' : 'Name'" [value]="terminal.ChaveTerminal"
                  [attr.selected]="terminal.NomeTerminal==currentSelectedTerminalName() ? true : null">
                  {{terminal.NomeTerminal}}</option>
          </select>
      </div>
      <div *ngIf="airportSelected()" class="row">
        <div class="button-estados button-estados--selected control">{{currentSelectedAirportName()}}</div>
      </div>
      <div *ngIf="airportSelected() && getQtdTerminais() > 0" class="row">
        <div class="button-estados button-estados--selected control">{{currentSelectedTerminalName()}}</div>
      </div>

      <div class="row">
        <div class="button-estados control" (click)="acessaVeiculo('CheckIn')">Cheguei</div>
      </div>
        <!-- <div class="row">
            <div class="button-estados control" (click)="acessaVeiculo('CheckOut')">Checkout</div>
        </div> -->
    </div>
</div>
