import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ChooseAirportComponent } from "./choose-airport/choose-airport.component";
import { MapaVeiculosComponent } from "./mapa-veiculos/mapa-veiculos.component";

const routes: Routes = [
  { path: "", component: ChooseAirportComponent },
  { path: "airport/:airport", component: ChooseAirportComponent },
  { path: "airport/:airport/:terminal", component: ChooseAirportComponent },
  { path: "mapaveiculos", component: MapaVeiculosComponent },
  { path: "mapaveiculos/:options", component: MapaVeiculosComponent },
  { path: "**", redirectTo: "/" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
