import { Component, OnInit } from "@angular/core";
import { Email } from "../model/Email";
import { ApiService } from "../services/api.service";
import { DataService } from "../services/data.service";
import { formatDate, DatePipe } from "@angular/common";

declare const gtag: Function;

@Component({
  selector: "app-feedback",
  templateUrl: "./feedback.component.html",
  styleUrls: ["./feedback.component.css"],
})
export class FeedbackComponent implements OnInit {
  public canDisplay: string = "none";
  public message: string = "";
  public messageAtiva: boolean = true;
  public statusErrorMessage: string = "";
  public statusSuccessMessage: string = "";

  constructor(
    private _dataService: DataService,
    private _apiService: ApiService
  ) {}

  ngOnInit(): void {}

  display() {
    this.canDisplay = "unset";
    this.statusErrorMessage = "";
    this.statusSuccessMessage = "";
    this.messageAtiva = true;
  }

  closeForm() {
    this.canDisplay = "none";
  }

  chamarVan() {
    this.enviar(
      "[TESTE-NAO ENVIAR]Solicito van no aeroporto " +
        this._dataService?.clientOptions?.Airport?.Sigla ?? "-"
    );

    this.messageAtiva = false;
  }
  enviar(msg?: string | null) {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString(undefined, {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    gtag("event", "feedback", {
      airport: this._dataService?.clientOptions?.Airport?.Sigla ?? "-",
    });

    const email: Email = {
      Assunto:
        "Feedback Totem " + this._dataService?.clientOptions?.Airport?.Name,
      Corpo:
        " Mensagem recebida via TOTEM no aeroporto " +
        this._dataService?.clientOptions?.Airport?.Name +
        "<br/>" +
        " Data/Hora " +
        formattedDate +
        "<br/>" +
        " Mensagem : " +
        (msg ?? this.message) +
        "<br/>",
      Sigla: this._dataService?.clientOptions?.Airport?.Sigla,
    };

    this._apiService.enviarEmail(email).subscribe(
      (res) => {
        if (res) {
          //this.canDisplay = "none";
          this.message = "";
          this.statusErrorMessage = "";
          this.statusSuccessMessage = "Sua mensagem foi enviada! Obrigado.";
        }
      },
      (err) => {
        this.statusErrorMessage =
          "Ocorreu um erro ao enviar o email. Tente novamente em instantes.";
        this.statusSuccessMessage = "";
      }
    );
  }
}
