<button type="button" class="right--over" (click)='display()'>FeedBack</button>


<div class="form-feedback-popup" [ngStyle]="{'display' : canDisplay }" id="feedbackForm">
  <form action="/" class="form-container">
    <h3>FeedBack</h3>

    <!-- <div class="form-control">
      <label for="email"><b>Email</b></label>
      <input type="email" placeholder="Email" name="email">
    </div> -->

    <div class="form-control">
      <label for="message"><b>Mensagem</b></label>
      <button type="submit" class="btn btn-chamavan" (click)="chamarVan()">Solicito van no aeroporto</button>
      <textarea rows="7" [(ngModel)]="message" [disabled]="!messageAtiva" placeholder="Digite sua Sugestão ou Reclamação" name="message" required ></textarea>
    </div>

    <button type="submit" class="btn" (click)="enviar()">Enviar</button>
    <button type="button" class="btn btn--cancel" (click)="closeForm()">Fechar</button>

    <div *ngIf="statusErrorMessage"  [ngClass]="{'error-message' : statusErrorMessage }" >
      <span class="error-text">{{statusErrorMessage}}</span>
    </div>
    <div *ngIf="statusSuccessMessage"  [ngClass]="{'success-message' : statusSuccessMessage }" >
      <span class="success-text">{{statusSuccessMessage}}</span>
    </div>
</form>
</div>
