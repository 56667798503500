import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { retry, catchError, map } from "rxjs/operators";
import { Airport } from "../model/Airport";
import { ResultadoPosicaoPorVeiculo } from "../model/ResultadoPosicaoPorVeiculo";
import { Veiculo } from "../model/Veiculo";
import { Posicao } from "../model/Posicao";
import { Local } from "../model/Local";
import { CercaInfo } from "../model/CercaInfo";
import { UserDataInfo } from "../model/UserDataInfo";
import { Email } from "../model/Email";
import { debug } from "console";
import { LastPoint } from "../model/LastPoint";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    }),
  };

  url: string = "https://sis.segminas.com.br/api/";
  urlLatLng: string = this.url + "?method=getLastVehicleFromLatlng";
  urlSegVansLocaliza: string = this.url + "?method=segVansLocaliza";
  urlSendEmail: string = this.url + "?method=sendEmail";
  urlSendSMS: string = this.url + "?method=sendSMS";
  urlgetDadosToten: string = this.url + "?method=insertDadosToten";
  urlNewApi: string = this.url + "localiza.php";
  TOKEN: string = "6dd93fe4-cf48-11e9-9afb-68b599b7a160";

  idOrganizacao: string = "205cd89e-58d0-11e6-8f59-666537666131";
  idEntidade: string = "56313b19-447d-4ab2-b581-b60be60f5faf";

  token: any;

  constructor(public http: HttpClient) {}

  newVanList(airport: Airport) {
    return this.http
      .post(
        this.urlNewApi,
        `txtAction=segVans&txtRegiao=` + airport.Regiao,
        this.httpOptions
      )
      .pipe(
        retry(1),
        catchError(this.errorHandl),
        map((res) => this.genResultadoNew(res))
      );
  }

  veiculos(airport: Airport): Observable<any> {
    return this.http
      .post(
        this.urlLatLng,
        `txtKey=` +
          this.TOKEN +
          `&txtLatitude=` +
          airport.LocationAirport.Latitude +
          `&txtLongitude=` +
          airport.LocationAirport.Longitude,
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  vansList(airport: Airport): Observable<ResultadoPosicaoPorVeiculo> {
    return this.http
      .post(
        this.urlSegVansLocaliza,
        `txtKey=` +
          this.TOKEN +
          `&txtLatitude=` +
          airport.LocationAirport.Latitude +
          `&txtLongitude=` +
          airport.LocationAirport.Longitude,
        this.httpOptions
      )
      .pipe(
        retry(1),
        catchError(this.errorHandl),
        map((res) => this.genResultado(res))
      );
  }

  enviarEmail(email: Email): Observable<any> {
    return this.http
      .post(
        this.urlSendEmail,
        `txtIdOrganizacao=` +
          this.idOrganizacao +
          `&txtIdEntidade=` +
          this.idEntidade +
          `&txtAssunto=` +
          email.Assunto +
          `&txtCorpoEmail=` +
          email.Corpo +
          `&txtSigla=` +
          email.Sigla,
        this.httpOptions
      )
      .pipe(
        retry(3),
        catchError(this.errorHandl),
        map((res) => {
          return true;
        })
      );
  }

  chamarVan(sigla: string): Observable<any> {
    return this.http
      .post(
        this.urlSendSMS,
        `txtIdOrganizacao=` +
          this.idOrganizacao +
          `&txtIdEntidade=` +
          this.idEntidade +
          `&txtSigla=` +
          sigla,
        this.httpOptions
      )
      .pipe(
        retry(2),
        catchError(this.errorHandl),
        map((res) => {
          return true;
        })
      );
  }

  setUserInfo(info: UserDataInfo): void {
    let local = null;
    console.log("setUserInfo:Try Get Local ");

    this.getPosition()
      .then((pos) => {
        console.log("setUserInfo:Get Local Success ");
        local = { Latitude: pos.lat, Longitude: pos.lng };
      })
      .catch(this.errorHandl)
      .finally(() => {
        console.log("setUserInfo:Try Get IP Address ");

        this.http
          .get("https://api.ipify.org/?format=json")
          .toPromise()
          .then((res: any) => {
            console.log("setUserInfo:Try Get IP Address Success ");

            info.Ip = res.ip;
            info.Date = new Date();
          })
          .catch(this.errorHandl)
          .finally(() => {
            console.log("setUserInfo:Try Send Log Utilizacao");

            this.http
              .post(
                this.urlgetDadosToten,
                `txtKey=` +
                  this.TOKEN +
                  `&txtLatitude=` +
                  (local?.Latitude ?? "") +
                  `&txtLongitude=` +
                  (local?.Longitude ?? "") +
                  `&txtDados=` +
                  JSON.stringify(info),
                this.httpOptions
              )
              .toPromise()
              .then((x: any) => {
                if (!x?.success) {
                  console.error(
                    "setUserInfo:Send Log Utilizacao Error" + x?.error
                  );
                } else {
                  console.log("setUserInfo:Send Log Utilizacao Success");
                }
              })
              .catch(this.errorHandl);
          });
        //   .pipe(
        //     catchError(this.errorHandl),
        //     map((res: any) => {

        //       console.log("setUserInfo:Try Send Log Utilizacao");

        //       this.http
        //       .post(
        //         this.urlgetDadosToten, `txtKey=` + this.TOKEN + `&txtLatitude=` + (local?.Latitude ?? '') + `&txtLongitude=` + (local?.Longitude ?? '') + `&txtDados=` + JSON.stringify(info)
        //         , this.httpOptions)
        //       .pipe(
        //         retry(1),
        //         catchError(this.errorHandl),
        //         map(res =>{
        //           debugger;
        //           console.log("setUserInfo:Send Log Utilizacao Success");
        //         } )
        //       );
        //     })
        //   );
      });
  }

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (resp) => {
          resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  genResultado(response: any) {
    let rs = new ResultadoPosicaoPorVeiculo();

    response.aData.forEach((el) => {
      var veiculo = new Veiculo(el.equipamento_numero, el.placa);

      el.last_positions.forEach((element) => {
        let dategps = moment(element.dh_gps);
        if (moment().diff(dategps, "hours", true) < 12) {
          veiculo.Posicoes.push(
            new Posicao(
              element.dh_gps.replace(" ", "T"),
              element.latitude,
              element.longitude
            )
          );
        } else {
          console.warn(
            "Veiculo com hora inválida : " +
              veiculo.Placa +
              ", " +
              element.dh_gps
          );
        }
      });

      rs.Veiculos.push(veiculo);
    });

    return rs;
  }

  genResultadoNew(response: any) {
    let rs = new ResultadoPosicaoPorVeiculo();

    response.aData.forEach((el) => {
      var last_point = JSON.parse(el.last_point);

      let ignition = el.posicoe_atual.ignition == "true" ? true : false;
      var veiculo = new Veiculo(el.eq_serial, el.ve_placa, ignition, {
        Id: last_point?.id,
        Descricao: last_point?.descricao,
        TipoEvento: last_point?.tipo_evento,
      });

      if (el.cerca_info != null) {
        el.cerca_info.forEach((cerca) => {
          let c = new CercaInfo(
            cerca.descricao,
            cerca.geo_cerca.type,
            cerca.sentido
          );

          cerca.geo_cerca.coordinates.forEach((p) => {
            for (let i = 0; i < p.length; i++) {
              let l = new Local();
              l.Latitude = p[i][0];
              l.Longitude = p[i][1];
              c.posicoes.push(l);
            }
          });

          veiculo.Cercas.push(c);
        });
      }

      el.last_positions.forEach((element) => {
        let dategps = moment(element.dh_gps);
        if (moment().diff(dategps, "hours", true) < 12) {
          veiculo.Posicoes.push(
            new Posicao(
              element.dh_gps.replace(" ", "T"),
              element.latitude,
              element.longitude
            )
          );
        } else {
          console.warn(
            "Veiculo com hora inválida : " +
              veiculo.Placa +
              ", " +
              element.dh_gps
          );
        }
      });

      if (veiculo.Posicoes.length > 0) rs.Veiculos.push(veiculo);
    });

    return rs;
  }

  errorHandl(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
