<div class="fab-container">
    <div class="fab fab-icon-holder">
        <i class="fas fa-smartcar" (click)='display()'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                <g id="smart_car" data-name="smart car">
                    <path
                        d="M27.67,18.64l-1.75-.44a1.16,1.16,0,0,1-.58-.37l-2.93-3.67A3.07,3.07,0,0,0,20,13H16a1,1,0,0,0,0,2h4a1.06,1.06,0,0,1,.84.41l2.94,3.67a3.11,3.11,0,0,0,1.66,1.06l1.74.44A1.08,1.08,0,0,1,28,21.63v2.14a.23.23,0,0,1-.23.23H25.65a3.48,3.48,0,0,0-6.3,0h-6.7a3.48,3.48,0,0,0-6.3,0H4.23A.23.23,0,0,1,4,23.77V21.63a1.08,1.08,0,0,1,.82-1.05l1.74-.44a3.11,3.11,0,0,0,1.66-1.06l2.94-3.67A1.06,1.06,0,0,1,12,15a1,1,0,0,0,0-2,3.07,3.07,0,0,0-2.41,1.16L6.66,17.83a1.16,1.16,0,0,1-.58.37l-1.75.44a3.07,3.07,0,0,0-2.33,3v2.14A2.23,2.23,0,0,0,4.23,26H6.05A3.48,3.48,0,0,0,13,26h6.1A3.48,3.48,0,0,0,26,26h1.82A2.23,2.23,0,0,0,30,23.77V21.63A3.07,3.07,0,0,0,27.67,18.64ZM9.5,27A1.5,1.5,0,1,1,11,25.5,1.5,1.5,0,0,1,9.5,27Zm13,0A1.5,1.5,0,1,1,24,25.5,1.5,1.5,0,0,1,22.5,27Z" />
                    <path d="M16,10a1,1,0,1,0,1,1,1,1,0,0,0-1-1Z" />
                    <path
                        d="M17.2,9.6a1,1,0,1,0,1.6-1.2,3.5,3.5,0,0,0-5.6,0,1,1,0,0,0,.2,1.4,1,1,0,0,0,.6.2,1,1,0,0,0,.8-.4A1.62,1.62,0,0,1,16,9,1.61,1.61,0,0,1,17.2,9.6Z" />
                    <path
                        d="M12.8,7.6A4.2,4.2,0,0,1,16,6a4.18,4.18,0,0,1,3.2,1.6A1,1,0,0,0,20,8a1,1,0,0,0,.6-.2,1,1,0,0,0,.2-1.4A6.19,6.19,0,0,0,16,4a6.17,6.17,0,0,0-4.8,2.4,1,1,0,0,0,1.6,1.2Z" />
                </g>
            </svg>
        </i>
    </div>
</div>


<div class="form-call-van-popup" [ngStyle]="{'display' : canDisplay }" id="callVanForm">
    <form action="/" class="form-container">
        <h3>Chamar Van?</h3>

        <button type="submit" class="btn" (click)="chamar()">Sim</button>
        <button type="button" class="btn btn--cancel" (click)="closeForm()">Não</button>
  </form>
</div>

<div class="form-call-response-popup" [ngStyle]="{'display' : canDisplayMessage }" id="callVanFormMessage">
  <form action="/" class="form-container">
      <h3>Resposta</h3>
      <div *ngIf="statusErrorMessage" class="message" [ngClass]="{'error-message' : statusErrorMessage }">
          <span class="message-text error-text">{{statusErrorMessage}}</span>
      </div>
      <div *ngIf="statusSuccessMessage" class="message" [ngClass]="{'success-message' : statusSuccessMessage }">
        <span class="message-text success-text">{{statusSuccessMessage}}</span>
    </div>
    <button type="button" class="btn btn--cancel" (click)="closeForm()">Ok</button>
</form>
</div>

<!--
<div class="fab-container">
    <div class="fab fab-icon-holder">
      <i class="fas fa-question"></i>
    </div>
    <ul class="fab-options">
      <li>
        <span class="fab-label">Documentation</span>
        <div class="fab-icon-holder">
          <i class="fas fa-file-alt"></i>
        </div>
      </li>
      <li>
        <span class="fab-label">Video Tutorials</span>
        <div class="fab-icon-holder">
          <i class="fas fa-video"></i>
        </div>
      </li>
      <li>
        <span class="fab-label">Live Chat</span>
        <div class="fab-icon-holder">
          <i class="fas fa-comments"></i>
        </div>
      </li>
      <li>
        <span class="fab-label">Feedback</span>
        <div class="fab-icon-holder">
          <i class="fas fa-comment-alt"></i>
        </div>
      </li>
    </ul>
  </div> -->
