import { Local } from "./Local";
import { Pin } from "./Pin";
import { Terminal } from "./Terminal";

class BaseAirport {}

export class Airport {
  public Sigla: string;
  public Name: string;
  public LocationAirport: Local;
  public LocationLocaliza: Local;
  public Regiao: string;
  public MinZoom?: number;
  public MaxZoom?: number;
  public DefaultZoom?: number;
  public ImageCheckIn?: string;
  public ImageCheckOut?: string;
  public CustomPin?: Array<Pin>;
  public IgnoreDirection?: boolean;
  public Terminais?: Array<Terminal>;
  public CurrentTerminal?: Terminal;
  public ChamaVan?: boolean;

  constructor(init?: Partial<Airport>) {
    Object.assign(this, init);
  }

  getLocationAirport(): Local {
    return this.CurrentTerminal?.Local ?? this.LocationAirport;
  }

  setTerminal(chaveTerminal: string): void {
    if (this.Terminais != null && this.Terminais.length > 0) {
      this.CurrentTerminal = this.Terminais.find(
        (x) => x.ChaveTerminal == chaveTerminal
      );
    }
  }
}
