// Se veículo refere-se ao status do mesmo
// Se ação do usuário, ele espera o veículo que está na ação oposta
// Ex.: se um cliente está em Checkin ele espera um veículo em ChekOut ou ChegandoAeroporto
//
export enum DirectionOptionEnum {
  CheckIn,
  CheckOut,
  ChegandoLocaliza,
  ChegandoAeroporto,
  ChegouLocaliza,
  ChegouAeroporto,
  NaoIdentificado,
  Manutencao,
  ChegouEmbarque,
  ChegouDesembarque,
  IgnoreDirection,
}

// Se veículo refere-se ao status do mesmo
// Se ação do usuário, ele espera o veículo que está na ação oposta
// Ex.: se um cliente está em Checkin ele espera um veículo em ChekOut
export enum DirectionEnum {
  Unknow,
  CheckIn,
  CheckOut,
}
