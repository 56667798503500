import { Airport } from "./Airport";
import { ClientOptions } from "./ClientOptions";
import { Local } from "./Local";

export class UserDataInfo {
    Path: string;
    Date: Date;
    Ip: string;
    ClientOptions: ClientOptions;

    constructor(){
    }
}