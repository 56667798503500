import { Component, OnInit } from "@angular/core";
import { LocationService } from "../services/location.service";
import { ClientOptions } from "../model/ClientOptions";
import { DirectionOptionEnum } from "../model/DirectionOptionEnum";
import { ActivatedRoute, Router } from "@angular/router";
import { DataService } from "../services/data.service";
import { Airport } from "../model/Airport";
import { ApiService } from "../services/api.service";
import { UserDataInfo } from "../model/UserDataInfo";
import { Terminal } from "../model/Terminal";

@Component({
  selector: "app-choose-airport",
  templateUrl: "./choose-airport.component.html",
  styleUrls: ["./choose-airport.component.css"],
})
export class ChooseAirportComponent implements OnInit {
  private _clientOption: ClientOptions;
  public _airports: Array<Airport>;
  public currentAirport: Airport;

  constructor(
    private _locationService: LocationService,
    private _dataService: DataService,
    private _apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._clientOption = new ClientOptions();
    this._airports = this._dataService.airports;
  }

  ngOnInit(): void {
    let udi = new UserDataInfo();
    udi.Date = new Date();
    udi.Path = "/";

    this._clientOption = this._dataService.getConfig();

    if (this._clientOption == null) {
      this._clientOption = new ClientOptions();
    } else if (this._clientOption.Airport) {
      this.escolherAeroporto(
        this._clientOption.Airport.Sigla,
        this._clientOption.Airport.CurrentTerminal?.ChaveTerminal
      );
    }

    this._apiService.setUserInfo(udi);
    // this._locationService.getPosition().then(pos => {
    //   // TODO: Criar regra para pré-selecionar aeroporto mais próximo
    //   console.log(`Positon: ${pos.lng} ${pos.lat}`);

    // });

    //this._clientOption.Airport = this._airports[0];
    // this._dataService
    // .getConfigsByNameAirport(this._airports[0].Sigla)
    // .then(airport =>
    //   this._clientOption.Airport = airport
    //   );

    this.route.params.subscribe((params) => {
      console.log("The id of this route is: ", params.airport);

      if (this.isValidAirport(params.airport)) {
        this.escolherAeroporto(params.airport, params.terminal, true);
      } else if (params.airport == "reset") {
        this._clientOption.FixedAirport = null;
        this._clientOption.Airport = null;
      }
    });
  }

  isValidAirport(name) {
    return name && this._dataService.existsAirpot(name);
  }

  airportSelected() {
    if (this._clientOption.FixedAirport && !this._clientOption.Airport)
      this._clientOption.FixedAirport = false;

    return this._clientOption.FixedAirport ?? false;
  }

  acessaVeiculo(local: string) {
    if (this._clientOption.Airport != null) {
      this._clientOption.Direcion = DirectionOptionEnum[local];

      this._dataService.clientOptions = this._clientOption;
      this._dataService.setConfig(this._clientOption);
      this.router.navigate(["/mapaveiculos/"]);
    }
  }

  escolherAeroportoHandler(event: any) {
    console.log(event.target.value);
    this.escolherAeroporto(event.target.value);
  }

  escolherTerminalHandler(event: any) {
    console.log(event.target.value);
    this.escolherAeroporto(this.currentAirport.Sigla, event.target.value);
  }

  getQtdTerminais() {
    return this.currentAirport?.Terminais?.length;
  }

  escolherAeroporto(
    sigla: string,
    chaveTerminal: string = null,
    fixedAirport: boolean = null
  ) {
    console.log(
      "escolhendo aeroporto.. " + sigla + " - terminal: " + chaveTerminal
    );

    this._dataService.getConfigsByNameAirport(sigla).then((x) => {
      this._clientOption.Airport = x;

      if (fixedAirport) this._clientOption.FixedAirport = fixedAirport;

      this._clientOption.Airport?.setTerminal(chaveTerminal);
      this.currentAirport = this._clientOption.Airport;
      this._dataService.setConfig(this._clientOption);

      if (fixedAirport) this.acessaVeiculo("CheckIn");
    });
  }

  currentSelectedAirportName() {
    return this._clientOption.Airport?.Name;
  }

  currentSelectedTerminalName() {
    return this._clientOption.Airport.CurrentTerminal?.NomeTerminal ?? "";
  }
}
