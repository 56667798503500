import { Injectable } from "@angular/core";
import { ClientOptions } from "../model/ClientOptions";
import { Airport } from "../model/Airport";

@Injectable({
  providedIn: "root",
})
export class DataService {
  clientOptions: ClientOptions;
  configName: string = "currentConfig";

  public airports: Array<Airport> = [
    new Airport({
      Sigla: "BHZ",
      Name: "Belo Horizonte",
      LocationAirport: {
        Endereco: "LMG-800 Km 7,9 s/n Cofins - MG",
        Latitude: -19.631964,
        Longitude: -43.963379,
      },
      LocationLocaliza: {
        Endereco: "LMG-800 Km 4 s/n Cofins - MG",
        Latitude: -19.660682,
        Longitude: -43.936379,
      },
      Regiao: "MG-CON",
      MinZoom: 13,
      MaxZoom: 22,
      ImageCheckIn: "assets/images/point-van.png",
      ImageCheckOut: "assets/images/point-van.png",
      ChamaVan: true,
    }),
    new Airport({
      Sigla: "CGH",
      Name: "São Paulo (Congonhas)",
      LocationAirport: {
        Endereco:
          "Praça Comandante Linneu Gomes, 373 - Santo Amaro, São Paulo - SP",
        Latitude: -23.627735290425,
        Longitude: -46.660342908083,
      },
      LocationLocaliza: {
        Endereco:
          "Rua Otávio Tarquínio de Sousa, 377 - Campo Belo, São Paulo - SP",
        Latitude: -23.620654286244,
        Longitude: -46.66553850085,
      },
      Regiao: "SP-CGH",
      MinZoom: 13,
      MaxZoom: 22,
    }),
    new Airport({
      Sigla: "GAL",
      Name: "Rio de Janeiro (Galeão)",
      LocationAirport: {
        Endereco: "Meier",
        Latitude: -22.812264696926,
        Longitude: -43.248476912476,
      },
      LocationLocaliza: {
        Endereco: "Localiza Meier - Galeão",
        Latitude: -22.816418051684,
        Longitude: -43.238524936693,
      },
      Regiao: "RJ-GAL",
      MinZoom: 13,
      MaxZoom: 22,
    }),
    new Airport({
      Sigla: "GRU",
      Name: "São Paulo (Guarulhos)",
      LocationAirport: {
        Endereco:
          "Rod. Hélio Smidt, s/nº - Aeroporto, Guarulhos - SP, 07190-100",
        Latitude: -23.425635,
        Longitude: -46.481569,
      },
      LocationLocaliza: {
        Endereco: "HG36+55 Cumbica, Guarulhos - SP",
        Latitude: -23.446768105774,
        Longitude: -46.490965848067,
      },
      Terminais: [
        {
          NomeTerminal: "Terminal 1",
          ChaveTerminal: "T1",
          Local: {
            Endereco:
              "Rod. Hélio Smidt, s/nº - Aeroporto, Guarulhos - SP, 07190-100",
            Latitude: -23.425635,
            Longitude: -46.481569,
          },
          CercasMonitoradasCheckIn: [
            "Area Interna Aeroporto Terminal 1 Guarulhos -Sp",
          ],
          CercasMonitoradasCheckOut: ["Localiza - Area Empresa Guarulhos -Sp"],
        },
        {
          NomeTerminal: "Terminal 2 Leste",
          ChaveTerminal: "T2L",
          Local: {
            Endereco:
              "Rod. Hélio Smidt, s/nº - Aeroporto, Guarulhos - SP, 07190-100",
            Latitude: -23.425635,
            Longitude: -46.481569,
          },
          CercasMonitoradasCheckIn: [
            "Area Interna Aeroporto Terminal 1 Guarulhos -Sp",
            "Área Interna Aeroporto Terminal 2 Leste- Guarulhos -Sp",
          ],
          CercasMonitoradasCheckOut: ["Localiza - Area Empresa Guarulhos -Sp"],
        },
        {
          NomeTerminal: "Terminal 2 Oeste",
          ChaveTerminal: "T2O",
          Local: {
            Endereco:
              "Rod. Hélio Smidt, s/nº - Aeroporto, Guarulhos - SP, 07190-100",
            Latitude: -23.425635,
            Longitude: -46.481569,
          },
          CercasMonitoradasCheckIn: [
            "Area Interna Aeroporto Terminal 1 Guarulhos -Sp",
            "Área Interna Aeroporto Terminal 2 Leste- Guarulhos -Sp",
            "Área Interna Aeroporto Terminal 2 Oeste- Guarulhos -Sp",
          ],
          CercasMonitoradasCheckOut: ["Localiza - Area Empresa Guarulhos -Sp"],
        },
        {
          NomeTerminal: "Terminal 3",
          ChaveTerminal: "T3",
          Local: {
            Endereco:
              "Rod. Hélio Smidt, s/nº - Aeroporto, Guarulhos - SP, 07190-100",
            Latitude: -23.425635,
            Longitude: -46.481569,
          },
          CercasMonitoradasCheckIn: [
            "Area Interna Aeroporto Terminal 1 Guarulhos -Sp",
            "Área Interna Aeroporto Terminal 2 Leste- Guarulhos -Sp",
            "Área Interna Aeroporto Terminal 2 Oeste- Guarulhos -Sp",
            "Área Interna Aeroporto Terminal 3 Guarulhos -Sp",
          ],
          CercasMonitoradasCheckOut: ["Localiza - Area Empresa Guarulhos -Sp"],
        },
      ],
      Regiao: "SP-GRU",
      MinZoom: 13,
      MaxZoom: 16,
    }),
    new Airport({
      Sigla: "CWB",
      Name: "Curitiba",
      LocationAirport: {
        Endereco: "Av. Rocha Pombo, São José dos Pinhais, PR, 83.010-900",
        Latitude: -25.536298,
        Longitude: -49.173999,
      },
      LocationLocaliza: {
        Endereco: "Av. Rocha Pombo, São José dos Pinhais, PR, 83.010-900",
        Latitude: -25.537951,
        Longitude: -49.176507,
      },
      Regiao: "PR-CUR",
      MinZoom: 13,
      MaxZoom: 16,
    }),
    new Airport({
      Sigla: "DF",
      Name: "Brasília",
      LocationAirport: {
        Endereco: "Av. Rocha Pombo, São José dos Pinhais, PR, 83.010-900",
        Latitude: -15.869826,
        Longitude: -47.921506,
      },
      LocationLocaliza: {
        Endereco: "Av. Rocha Pombo, São José dos Pinhais, PR, 83.010-900",
        Latitude: -15.870849,
        Longitude: -47.931525,
      },
      Regiao: "DF",
      MinZoom: 13,
      MaxZoom: 16,
    }),
    new Airport({
      Sigla: "GYN",
      Name: "Goiania",
      LocationAirport: {
        Endereco: "Goiania, GO",
        Latitude: -16.628779,
        Longitude: -49.214254,
      },
      LocationLocaliza: {
        Endereco: "Alameda Dois, s/n - Santa Genoveva",
        Latitude: -16.628329,
        Longitude: -49.207535,
      },
      Regiao: "GO",
    }),
    new Airport({
      Sigla: "BA",
      Name: "Salvador",
      LocationAirport: {
        Endereco: "Praça Gago Coutinho - São Cristóvão Salvador - BA 41510-045",
        Latitude: -12.914275,
        Longitude: -38.335218,
      },
      LocationLocaliza: {
        Endereco: "Tv. Santos Dumont - São Cristóvão Salvador - BA 41301-110",
        Latitude: -12.906524,
        Longitude: -38.341499,
      },
      Regiao: "BA",
      MinZoom: 13,
      MaxZoom: 22,
    }),
    new Airport({
      Sigla: "SC-FLO",
      Name: "Florianópolis",
      LocationAirport: {
        Endereco:
          "Rod. Ac. ao Aeroporto, nº 6.200 - Carianos, Florianópolis - SC, 88047-902",
        Latitude: -27.664857,
        Longitude: -48.544459,
      },
      LocationLocaliza: {
        Endereco:
          "Av. Dep. Diomício Freitas, 3393 - Carianos, Florianópolis - SC, 88047-900",
        Latitude: -27.674318,
        Longitude: -48.545486,
      },
      Regiao: "SC-FLO",
    }),
    new Airport({
      Sigla: "RS",
      Name: "Porto Alegre",
      LocationAirport: {
        Endereco:
          "Av. Severo Dullius, 90.010 - São João, Porto Alegre - RS, 90200-310",
        Latitude: -29.989948,
        Longitude: -51.17659,
      },
      LocationLocaliza: {
        Endereco:
          "INTERN SALGADO FILHO, S/N - São João, Porto Alegre - RS, 90001-97047-900",
        Latitude: -29.991315,
        Longitude: -51.183412,
      },
      Regiao: "RS",
      MinZoom: 13,
      MaxZoom: 22,
    }),
    new Airport({
      Sigla: "MT-CUI",
      Name: "Cuiabá",
      LocationAirport: {
        Endereco:
          "Av. João Ponce de Arruda, s/n - Jardim Aeroporto, Várzea Grande - MT, 78110-900",
        Latitude: -29.989948,
        Longitude: -51.17659,
      },
      LocationLocaliza: {
        Endereco:
          "Av. João Ponce de Arruda, 820 - Centro Sul, Várzea Grande - MT, 78125-300",
        Latitude: -29.991315,
        Longitude: -51.183412,
      },
      Regiao: "MT-CUI",
    }),
    new Airport({
      Sigla: "PA-BEL",
      Name: "Belém",
      LocationAirport: {
        Endereco: "Av. Júlio César, s/n - Val-de-Cans, Belém - PA, 66115-970",
        Latitude: -1.389762,
        Longitude: -48.479882,
      },
      LocationLocaliza: {
        Endereco: "Av. Júlio César, S/N - Val de Caes, Belém - PA, 66115-970",
        Latitude: -1.39223,
        Longitude: -48.480006,
      },
      Regiao: "PA-BEL",
    }),
    new Airport({
      Sigla: "PI",
      Name: "Terezina",
      LocationAirport: {
        Endereco: "Av. Centenário, s/n - Aeroporto, Teresina - PI, 64006-700",
        Latitude: -5.063463,
        Longitude: -42.821113,
      },
      LocationLocaliza: {
        Endereco:
          "Av. Júlio Av. Centenário, 1754 - Aeroporto, Teresina - PI, 64003-700",
        Latitude: -5.064212,
        Longitude: -42.818828,
      },
      Regiao: "PI",
    }),
    new Airport({
      Sigla: "CE",
      Name: "Fortaleza",
      LocationAirport: {
        Endereco:
          "Av. Senador Carlos Jereissati, 3000 - Aeroporto, Fortaleza - CE, 60741-970",
        Latitude: -3.778788,
        Longitude: -38.540759,
      },
      LocationLocaliza: {
        Endereco:
          "Av. Senador Carlos Jereissati, 3000 - Serrinha, Fortaleza - CE, 60182-420",
        Latitude: -3.780716,
        Longitude: -38.536257,
      },
      Regiao: "CE",
    }),
    new Airport({
      Sigla: "RN",
      Name: "Natal",
      LocationAirport: {
        Endereco:
          "Av. Ruy Pereira dos Santos, 3100, São Gonçalo do Amarante - RN, 59290-000",
        Latitude: -5.762836,
        Longitude: -35.372496,
      },
      LocationLocaliza: {
        Endereco:
          "Av. Ruy Pereira dos Santos, 3100 - Aeroporto, São Gonçalo do Amarante - RN, 59290-000",
        Latitude: -5.761903,
        Longitude: -35.369733,
      },
      Regiao: "RN",
    }),
    new Airport({
      Sigla: "PB",
      Name: "João Pessoa",
      LocationAirport: {
        Endereco:
          "Entrada do Terminal de Passageiros - Térreo - Aeroporto, Bayeux - PB",
        Latitude: -7.145511,
        Longitude: -34.948192,
      },
      LocationLocaliza: {
        Endereco: "Av. Mal. Rondon, S/n - Aeroporto, Bayeux - PB, 58308-190",
        Latitude: -7.143705,
        Longitude: -34.947861,
      },
      Regiao: "PB",
    }),
    new Airport({
      Sigla: "PE",
      Name: "Recife",
      CustomPin: [
        {
          Point: {
            Latitude: -8.132208,
            Longitude: -34.91754,
          },
          Text: "Embarque",
          Icon: "assets/images/flag.png",
        },
        {
          Point: {
            Latitude: -8.131467,
            Longitude: -34.917844,
          },
          Text: "Desembarque",
          Icon: "assets/images/red-flag.png",
        },
      ],
      IgnoreDirection: true,
      LocationAirport: {
        Endereco:
          "Praça Min. Salgado Filho, s/n - Imbiribeira, Recife - PE, 51210-902",
        Latitude: -8.131455,
        Longitude: -34.917951,
      },
      LocationLocaliza: {
        Endereco: "RM Imbiribeira, Recife - PE",
        Latitude: -8.133001,
        Longitude: -34.916173,
      },
      Regiao: "PE",
      MinZoom: 13,
      MaxZoom: 22,
      DefaultZoom: 19,
    }),
    new Airport({
      Sigla: "MT-SBCG",
      Name: "Campo Grande",
      LocationAirport: {
        Endereco:
          "Aeroporto Antonio Joao Ferreira, 490 - Jardim Petropolis, Campo Grande - MS, 79102-070",
        Latitude: -20.457077,
        Longitude: -54.668879,
      },
      LocationLocaliza: {
        Endereco:
          "Para falar conosco envie um WhatsApp para 0800 979 2020, Av. Duque de Caxias, 4355 - Vila Serradinho, Campo Grande - MS, 79101-901",
        Latitude: -20.456256,
        Longitude: -54.673979,
      },
      Regiao: "MT-SBCG",
      MinZoom: 16,
      MaxZoom: 16,
    }),
    new Airport({
      Sigla: "PA-PAR",
      Name: "Carajás",
      LocationAirport: {
        Endereco: "PA-275, Parauapebas - PA, 68515-000",
        Latitude: -6.114267,
        Longitude: -50.000933,
      },
      LocationLocaliza: {
        Endereco: "R. E, 449 - Cidade Nova, Parauapebas - PA, 68515-000",
        Latitude: -6.065593,
        Longitude: -49.909992,
      },
      Regiao: "PA-PAR",
      MinZoom: 13,
      MaxZoom: 16,
    }),
    new Airport({
      Sigla: "PR-FIG",
      Name: "Foz do Iguaçu",
      LocationAirport: {
        Endereco:
          "Rod. BR 469, Km 16,5, s/n - Aeroporto, Foz do Iguaçu - PR, 85863-900",
        Latitude: -25.59768,
        Longitude: -54.489049,
      },
      LocationLocaliza: {
        Endereco:
          "Av. das Cataratas, S/N - Acesso Aeroporto, Foz do Iguaçu - PR, 85853-880",
        Latitude: -25.599873,
        Longitude: -54.490752,
      },
      Regiao: "PR-FIG",
      MinZoom: 14,
      MaxZoom: 16,
    }),
    new Airport({
      Sigla: "MA-IMP",
      Name: "Imperatriz",
      LocationAirport: {
        Endereco:
          "Avenida Moacyr Spósito Ribeiro, S/N - Aeroporto, Imperatriz - MA, 65913-415",
        Latitude: -5.531838,
        Longitude: -47.455975,
      },
      LocationLocaliza: {
        Endereco:
          "Avenida Moacyr Sposito Ribeiro, S/N - Parque Alvorada, Imperatriz - MA, 65915-000",
        Latitude: -5.532202,
        Longitude: -47.456219,
      },
      Regiao: "MA-IMP",
      MinZoom: 14,
      MaxZoom: 16,
    }),
    new Airport({
      Sigla: "SC-JOI",
      Name: "Joinville",
      LocationAirport: {
        Endereco: "Av. Santos Dumont, 9000 - Joinville, SC, 89223-600",
        Latitude: -26.224394,
        Longitude: -48.80182,
      },
      LocationLocaliza: {
        Endereco: "Av. Santos Dumont - Aventureiro, Joinville - SC, 89226-435",
        Latitude: -26.22528,
        Longitude: -48.801782,
      },
      Regiao: "SC-JOI",
      MinZoom: 13,
      MaxZoom: 22,
    }),
    new Airport({
      Sigla: "AL",
      Name: "Maceió",
      LocationAirport: {
        Endereco:
          "Rodovia BR, 104 Km 91 - Tabuleiro do Pinto, Rio Largo - AL, 57100-971",
        Latitude: -9.515273,
        Longitude: -35.79262,
      },
      LocationLocaliza: {
        Endereco:
          "Rodovia BR-104 - Tabuleiro do Pinto, Rio Largo - AL, 57100-971",
        Latitude: -9.517099,
        Longitude: -35.792924,
      },
      Regiao: "AL",
      MinZoom: 14,
      MaxZoom: 16,
    }),
    new Airport({
      Sigla: "PA-MAR",
      Name: "Marabá",
      LocationAirport: {
        Endereco: "Parque Laranjeiras, Marabá - PA",
        Latitude: -5.3718,
        Longitude: -49.136826,
      },
      LocationLocaliza: {
        Endereco:
          "Aeroporto de Marabá - R. do Aeroporto - Amapá, Marabá - PA, 68502-290",
        Latitude: -5.372953,
        Longitude: -49.136905,
      },
      Regiao: "PA-MAR",
      MinZoom: 14,
      MaxZoom: 16,
    }),
    new Airport({
      Sigla: "SC-NAV",
      Name: "Navegantes",
      LocationAirport: {
        Endereco:
          "R. Manoel Leopoldo Rocha, 470 - Centro, Navegantes - SC, 88375-000",
        Latitude: -26.880698,
        Longitude: -48.648988,
      },
      LocationLocaliza: {
        Endereco:
          "R. Manoel Leopoldo Rocha, 1297 - Centro, Navegantes - SC, 88375-000",
        Latitude: -26.882284,
        Longitude: -48.650699,
      },
      Regiao: "SC-NAV",
      MinZoom: 15,
      MaxZoom: 16,
    }),
    new Airport({
      Sigla: "RJ-STD",
      Name: "Rio de Janeiro (Santos Dumont)",
      LocationAirport: {
        Endereco:
          "Praça Sen. Salgado Filho, s/n - Centro, Rio de Janeiro - RJ, 20021-340",
        Latitude: -22.910346,
        Longitude: -43.167658,
      },
      LocationLocaliza: {
        Endereco:
          "R. Jardel Jércolis, S/N° - Glória, Rio de Janeiro - RJ, 20021-150",
        Latitude: -22.913121,
        Longitude: -43.169859,
      },
      Regiao: "RJ-STD",
      MinZoom: 13,
      MaxZoom: 22,
      ImageCheckIn: "assets/images/point-van.png",
      ImageCheckOut: "assets/images/point-van.png",
    }),
    new Airport({
      Sigla: "MA-SLU",
      Name: "São Luiz",
      LocationAirport: {
        Endereco:
          "Av. dos Libaneses, 3503 - Tirirical, São Luís - MA, 65056-480",
        Latitude: -2.58323,
        Longitude: -44.236391,
      },
      LocationLocaliza: {
        Endereco:
          "Av. dos Libaneses, 3503 - Tirirical, São Luís - MA, 65056-480",
        Latitude: -2.581618,
        Longitude: -44.240312,
      },
      Regiao: "MA-SLU",
      MinZoom: 16,
      MaxZoom: 16,
    }),
    new Airport({
      Sigla: "MT-SIN",
      Name: "Sinop",
      LocationAirport: {
        Endereco: "Rod. dos Pioneiros, s/n - Zona Rural, Sinop - MT",
        Latitude: -11.878589,
        Longitude: -55.581577,
      },
      LocationLocaliza: {
        Endereco:
          "R. das Nogueiras, 1420 - St. Comercial, Sinop - MT, 78550-194",
        Latitude: -11.854903,
        Longitude: -55.510945,
      },
      Regiao: "MT-SIN",
      MinZoom: 12,
      MaxZoom: 16,
    }),
    new Airport({
      Sigla: "MG-UBE",
      Name: "Uberlândia",
      LocationAirport: {
        Endereco:
          "R. Salgado Filho, 100 - Aeroporto, Uberlândia - MG, 38406-394",
        Latitude: -18.889463,
        Longitude: -48.23101,
      },
      LocationLocaliza: {
        Endereco: "Localiza",
        Latitude: -18.904274,
        Longitude: -48.259979,
      },
      Regiao: "MG-UBE",
      MinZoom: 13,
      MaxZoom: 16,
    }),
  ];

  constructor() {}

  existsAirpot(name: string): boolean {
    return this.airports.find((x) => x.Sigla == name) != null;
  }

  getConfigsByNameAirport(name: string): Promise<Airport> {
    return new Promise((resolve, reject) => {
      resolve(this.airports.find((x) => x.Sigla == name));
    });
  }

  setConfig(options: ClientOptions) {
    sessionStorage.setItem(this.configName, JSON.stringify(options));
  }

  getConfig(): ClientOptions {
    let str = sessionStorage.getItem(this.configName);
    if (str == null) return null;

    let cfg = new ClientOptions(JSON.parse(str));
    cfg.Airport = new Airport(cfg.Airport);
    return cfg;
  }
}
