import { Posicao } from "./Posicao";
import { DirectionEnum, DirectionOptionEnum } from "./DirectionOptionEnum";
import { ClientOptions } from "./ClientOptions";
import { ThrowStmt } from "@angular/compiler";
import { CercaInfo } from "./CercaInfo";
import { LastPoint } from "./LastPoint";
import { startWith } from "rxjs/operators";
import { Airport } from "./Airport";
declare var L: any;

const METERS_REFERENCE_DISTANCE_BETWEEN_POINT: number = 1;
const METERS_REFERENCE_DISTANCE_BETWEEN_POINT_AND_TARGET: number = 40;
const METERS_REFERENCE_CHECKPOINT_CHEGANDO: number = 50;
const METERS_REFERENCE_CHECKPOINT_CHEGOU: number = 5;

export class Veiculo {
  Placa: string;
  NumeroSerie: string;

  Posicoes: Array<Posicao>;
  marker: any;
  Ignicao: boolean;
  Cercas: Array<CercaInfo>;
  LastPoint: LastPoint;

  constructor(
    numeroSerie: string,
    placa: string,
    ignicao?: boolean,
    last_point?: LastPoint
  ) {
    this.Placa = placa;
    this.NumeroSerie = numeroSerie;
    this.Posicoes = new Array<Posicao>();
    this.Cercas = new Array<CercaInfo>();
    this.Ignicao = ignicao ?? false;
    this.LastPoint = last_point;
  }

  isValid(): boolean {
    var ax = new Date();
    ax.setMinutes(ax.getMinutes() - 15);
    return this.getLastPosition().data > ax;
  }

  getDirectionByServer(airport: Airport): DirectionEnum {
    if ((this.LastPoint.Descricao?.length ?? 0) <= 0) {
      return DirectionEnum.Unknow;
    }

    if (airport.CurrentTerminal?.CercasMonitoradasCheckIn ?? false) {
      return this.getDirectionByCercasCadastradas(airport);
    } else {
      return this.getDirectionByNameLastPoint();
    }
  }

  getDirectionByCercasCadastradas(airport: Airport): DirectionEnum {
    if (
      airport.CurrentTerminal.CercasMonitoradasCheckIn.indexOf(
        this.LastPoint.Descricao
      ) >= 0
    ) {
      return DirectionEnum.CheckIn;
    }
    if (
      airport.CurrentTerminal.CercasMonitoradasCheckOut.indexOf(
        this.LastPoint.Descricao
      ) >= 0
    ) {
      return DirectionEnum.CheckOut;
    }
    return DirectionEnum.Unknow;
  }

  getDirectionByNameLastPoint() {
    console.log(
      "LastPoint[Descricao]: " + this.Placa + ":" + this.LastPoint.Descricao
    );

    if (
      this.LastPoint.Descricao.indexOf("Aeroporto") >= 0 ||
      this.LastPoint.Descricao.indexOf("Terminal") >= 0
    )
      return DirectionEnum.CheckIn; // Saindo do Aeroporto e indo em direação a Localiza

    if (
      this.LastPoint.Descricao.indexOf("Agencia") >= 0 ||
      this.LastPoint.Descricao.indexOf("Agência") >= 0 ||
      this.LastPoint.Descricao.indexOf("Localiza") >= 0 ||
      this.LastPoint.Descricao.indexOf("Desembarque") >= 0 ||
      this.LastPoint.Descricao.indexOf("Embarque") >= 0
    )
      return DirectionEnum.CheckOut; // Saindo da Localiza e indo em direação ao Aeroporto

    return DirectionEnum.Unknow;
  }

  getLastPosition(): Posicao {
    if (this.Posicoes.length > 1) {
      return this.getPosicoesDescendingOrder()[0];
    } else {
      return null;
    }
  }

  getAngle(): number {
    let posicoes = this.getPosicoesDescendingOrder();

    let origin = posicoes[posicoes.length - 2];
    let destin = posicoes[posicoes.length - 1];

    return this.bearing(
      origin.latitude,
      origin.longitude,
      destin.latitude,
      destin.longitude
    );
  }

  // Converts from degrees to radians.
  toRadians(degrees) {
    return (degrees * Math.PI) / 180;
  }

  // Converts from radians to degrees.
  toDegrees(radians) {
    return (radians * 180) / Math.PI;
  }

  bearing(startLat, startLng, destLat, destLng) {
    startLat = this.toRadians(startLat);
    startLng = this.toRadians(startLng);
    destLat = this.toRadians(destLat);
    destLng = this.toRadians(destLng);

    let y = Math.sin(destLng - startLng) * Math.cos(destLat);
    let x =
      Math.cos(startLat) * Math.sin(destLat) -
      Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
    let brng = Math.atan2(y, x);
    brng = this.toDegrees(brng);
    return (brng + 360) % 360;
  }

  getPosicoesDescendingOrder(): Array<Posicao> {
    return this.Posicoes.sort((a, b) => {
      return b.data.getTime() - a.data.getTime();
    });
  }

  getDirection(clientOptions: ClientOptions): DirectionOptionEnum {
    var rs = this._getDirection(clientOptions);
    console.log(this.Placa + " Result:" + `${DirectionOptionEnum[rs]}`);

    return rs;
  }

  getIcon(clientOptions: ClientOptions): string {
    let dir = this.getDirection(clientOptions);

    switch (dir) {
      case DirectionOptionEnum.CheckIn:
      case DirectionOptionEnum.ChegandoLocaliza:
      case DirectionOptionEnum.ChegouLocaliza:
        return clientOptions.Airport.ImageCheckIn ?? "assets/images/van.png";
      case DirectionOptionEnum.CheckOut:
      case DirectionOptionEnum.ChegandoAeroporto:
      case DirectionOptionEnum.ChegouAeroporto:
      case DirectionOptionEnum.ChegouEmbarque:
      case DirectionOptionEnum.ChegouDesembarque:
        return clientOptions.Airport.ImageCheckOut ?? "assets/images/van.png";
      default:
        return "assets/images/van.png";
    }
  }

  _getDirection(clientOptions: ClientOptions): DirectionOptionEnum {
    var posTeste = this.getPosicoesDescendingOrder();

    var distance1Airport = L.GeometryUtil.length([
      L.latLng(
        clientOptions.Airport.LocationAirport.Latitude,
        clientOptions.Airport.LocationAirport.Longitude
      ),
      L.latLng(posTeste[0].latitude, posTeste[0].longitude),
    ]);
    var distance1Localiza = L.GeometryUtil.length([
      L.latLng(
        clientOptions.Airport.LocationLocaliza.Latitude,
        clientOptions.Airport.LocationLocaliza.Longitude
      ),
      L.latLng(posTeste[0].latitude, posTeste[0].longitude),
    ]);

    console.log(
      this.Placa +
        " Test:" +
        posTeste[0].data +
        "{" +
        posTeste[0].latitude +
        "," +
        posTeste[0].longitude +
        "}"
    );
    var pos = 0;
    do {
      for (let it = 0; it < this.Cercas.length; it++) {
        let cerca = this.Cercas[it];

        if (cerca.ehCercaAreaUtil()) {
          if (
            !cerca.estaDentro(posTeste[pos].latitude, posTeste[pos].longitude)
          )
            return DirectionOptionEnum.Manutencao;

          continue;
        }

        if (cerca.ehCercaEmbarque()) {
          if (
            cerca.estaDentro(posTeste[pos].latitude, posTeste[pos].longitude)
          ) {
            console.log(
              "Chegou Embarque, identificado pela posição : Cerca (" +
                cerca.descricao +
                ")"
            );
            return DirectionOptionEnum.ChegouEmbarque;
          }
        }

        if (cerca.ehCercaDesembarque()) {
          if (
            cerca.estaDentro(posTeste[pos].latitude, posTeste[pos].longitude)
          ) {
            console.log(
              "Chegou Desembarque, identificado pela posição : Cerca (" +
                cerca.descricao +
                ")"
            );
            return DirectionOptionEnum.ChegouDesembarque;
          }
        }

        if (cerca.estaDentro(posTeste[pos].latitude, posTeste[pos].longitude)) {
          if (
            clientOptions?.Airport?.CurrentTerminal?.NomeTerminal?.length > 0
          ) {
            if (
              cerca.descricao
                .toLowerCase()
                .indexOf(
                  clientOptions?.Airport?.CurrentTerminal?.NomeTerminal?.toLowerCase()
                ) >= 0
            ) {
              console.log(
                "Chegou, identificado pela posição(terminal) : Cerca (" +
                  cerca.descricao +
                  ")"
              );
              return DirectionOptionEnum.ChegouAeroporto;
            } else {
              // caso tenha terminal e nao encontre terminal continua a pesquisa
              console.log(
                "Chegou em terminal diferente, continuando avaliacao : Cerca (" +
                  cerca.descricao +
                  ")"
              );
            }
          } else {
            console.log(
              "Chegou, identificado pela posição : Cerca (" +
                cerca.descricao +
                ")"
            );
            if (cerca.descricao.toLowerCase().indexOf("aeroporto") >= 0) {
              return DirectionOptionEnum.ChegouAeroporto;
            } else {
              return DirectionOptionEnum.ChegouLocaliza;
            }
          }
        }
      }

      console.log(
        this.Placa +
          " Test:" +
          posTeste[pos].data +
          "{" +
          posTeste[pos].latitude +
          "," +
          posTeste[pos].longitude +
          "}"
      );

      // if (distance1Airport < METERS_REFERENCE_CHECKPOINT_CHEGOU) return DirectionOptionEnum.ChegouAeroporto;
      // if (distance1Localiza < METERS_REFERENCE_CHECKPOINT_CHEGOU) return DirectionOptionEnum.ChegouLocaliza;

      var distance2Airport = L.GeometryUtil.length([
        L.latLng(
          clientOptions.Airport.LocationAirport.Latitude,
          clientOptions.Airport.LocationAirport.Longitude
        ),
        L.latLng(posTeste[pos].latitude, posTeste[pos].longitude),
      ]);
      var distance2Localiza = L.GeometryUtil.length([
        L.latLng(
          clientOptions.Airport.LocationLocaliza.Latitude,
          clientOptions.Airport.LocationLocaliza.Longitude
        ),
        L.latLng(posTeste[pos].latitude, posTeste[pos].longitude),
      ]);

      // if (distance1Airport < METERS_REFERENCE_CHECKPOINT_CHEGANDO) return DirectionOptionEnum.ChegandoAeroporto;
      // if (distance1Localiza < METERS_REFERENCE_CHECKPOINT_CHEGANDO) return DirectionOptionEnum.ChegandoLocaliza;
      console.log(
        " Distance (Aeroporto d1/d2): " +
          distance1Airport +
          "/" +
          distance2Airport +
          " Localiza d1/d2: " +
          distance1Localiza +
          "/" +
          distance2Localiza
      );

      if (
        this.getDirectionByServer(clientOptions.Airport) ==
        DirectionEnum.CheckIn
      ) {
        // || distance1Airport > distance1Localiza) {
        if (this.estaChegandoLocaliza(distance2Localiza, distance1Localiza)) {
          return DirectionOptionEnum.ChegandoLocaliza;
        }

        if (this.estaChegandoAeroporto(distance2Airport, distance1Airport)) {
          return DirectionOptionEnum.ChegandoAeroporto;
        }

        return DirectionOptionEnum.CheckIn;
      } else if (
        this.getDirectionByServer(clientOptions.Airport) ==
        DirectionEnum.CheckOut
      ) {
        if (this.estaChegandoAeroporto(distance2Airport, distance1Airport)) {
          return DirectionOptionEnum.ChegandoAeroporto;
        }

        if (this.estaChegandoLocaliza(distance2Localiza, distance1Localiza)) {
          return DirectionOptionEnum.ChegandoLocaliza;
        }

        return DirectionOptionEnum.CheckOut;
      }

      // if (this.getDirectionByServer() == DirectionEnum.CheckIn || (distance2Localiza > distance1Localiza && Math.abs(distance1Localiza - distance2Localiza) > METERS_REFERENCE_DISTANCE_BETWEEN_POINT)) return DirectionOptionEnum.CheckIn;
      // if (this.getDirectionByServer() == DirectionEnum.CheckOut || (distance2Airport > distance1Airport && Math.abs(distance1Airport - distance2Airport) > METERS_REFERENCE_DISTANCE_BETWEEN_POINT)) return DirectionOptionEnum.CheckOut;

      pos++;

      //sendo agressivo testando apenas a última posição
    } while (pos < posTeste.length && pos < 1);

    return DirectionOptionEnum.NaoIdentificado;
  }

  private estaChegandoAeroporto(distance2Airport: any, distance1Airport: any) {
    if (
      distance2Airport > distance1Airport &&
      Math.abs(distance1Airport - distance2Airport) >
        METERS_REFERENCE_DISTANCE_BETWEEN_POINT &&
      distance1Airport < METERS_REFERENCE_CHECKPOINT_CHEGANDO
    )
      return true;
    return false;
  }

  private estaChegandoLocaliza(distance2Localiza: any, distance1Localiza: any) {
    if (
      distance2Localiza > distance1Localiza &&
      Math.abs(distance1Localiza - distance2Localiza) >
        METERS_REFERENCE_DISTANCE_BETWEEN_POINT &&
      distance1Localiza < METERS_REFERENCE_CHECKPOINT_CHEGANDO
    )
      return true;
    return false;
  }

  resumeDirecion(direcao: DirectionOptionEnum): DirectionOptionEnum {
    switch (direcao) {
      case DirectionOptionEnum.CheckIn:
      case DirectionOptionEnum.ChegandoLocaliza:
      case DirectionOptionEnum.ChegouLocaliza:
        return DirectionOptionEnum.CheckIn;
      case DirectionOptionEnum.CheckOut:
      case DirectionOptionEnum.ChegandoAeroporto:
      case DirectionOptionEnum.ChegouAeroporto:
        return DirectionOptionEnum.CheckOut;
      default:
        return DirectionOptionEnum.NaoIdentificado;
    }
  }

  getMessage(direcao: DirectionOptionEnum) {
    switch (direcao) {
      case DirectionOptionEnum.CheckIn:
        return "Checkin";
      //return "Chegando para Alugar um Veículo";
      case DirectionOptionEnum.CheckOut:
        return "CheckOut";
      //return "Saindo da Cidade";
      case DirectionOptionEnum.ChegandoAeroporto:
      case DirectionOptionEnum.ChegandoLocaliza:
        return "Chegando...";
      case DirectionOptionEnum.ChegouAeroporto:
      case DirectionOptionEnum.ChegouLocaliza:
        return "Cheguei";
      default:
        return "N/A";
      //return "Não foi possível identificar a Direção";
    }
  }
}
