import { Component, OnInit } from "@angular/core";
import { ApiService } from "../services/api.service";
import { DataService } from "../services/data.service";

declare const gtag: Function;
@Component({
  selector: "app-call-van",
  templateUrl: "./call-van.component.html",
  styleUrls: ["./call-van.component.css"],
})
export class CallVanComponent implements OnInit {
  public canDisplay: string = "none";
  public canDisplayMessage: string = "none";
  public message: string = "";
  public statusErrorMessage: string = "";
  public statusSuccessMessage: string = "";

  constructor(
    private _apiService: ApiService,
    private _dataService: DataService
  ) {}

  ngOnInit(): void {}

  display() {
    this.canDisplay = "unset";
  }

  closeForm() {
    this.canDisplay = "none";
    this.canDisplayMessage = "none";
  }

  chamar() {
    let config = this._dataService.getConfig();

    gtag("event", "chama_van", {
      airport: config.Airport.Sigla ?? "-",
    });

    this._apiService.chamarVan(config.Airport.Sigla ?? "-").subscribe(
      (res) => {
        this.canDisplay = "none";

        this.canDisplayMessage = "unset";
        this.message = "";
        this.statusErrorMessage = "";
        this.statusSuccessMessage =
          "A van foi chamada e chegará em alguns instantes.";
      },
      (err) => {
        this.canDisplay = "none";
        this.canDisplayMessage = "unset";
        this.statusErrorMessage =
          "Ocorreu um erro ao chamar a Van. Aguarde um pouco e tente novamente.";
        this.statusSuccessMessage = "";
      }
    );
  }
}
