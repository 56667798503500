import { Component, OnInit } from "@angular/core";
import { DataService } from "../services/data.service";
import { ClientOptions } from "../model/ClientOptions";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../services/api.service";
import { _getOptionScrollPosition } from "@angular/material/core";
import { NgxXml2jsonService } from "ngx-xml2json";
import { TimeGeoService } from "../services/time-geo.service";
import {
  DirectionEnum,
  DirectionOptionEnum,
} from "../model/DirectionOptionEnum";
import { Veiculo } from "../model/Veiculo";
import { Buffer } from "buffer";
import { Geometry } from "wkx";
import { UserDataInfo } from "../model/UserDataInfo";
import { FeedbackComponent } from "../feedback/feedback.component";
import { CallVanComponent } from "../call-van/call-van.component";

//declare var wkx : any

//import * as xlsToJsonParser from 'xml2json';

declare var L: any;
declare const gtag: Function;

@Component({
  selector: "app-mapa-veiculos",
  templateUrl: "./mapa-veiculos.component.html",
  styleUrls: ["./mapa-veiculos.component.css"],
})
export class MapaVeiculosComponent implements OnInit {
  public clientOptions: ClientOptions;
  private _map: any;
  private _markers: any[];
  private _polygon: any[];
  private jsonKml: any;
  private _mostaCerca: boolean;

  constructor(
    private _dataService: DataService,
    private _apiService: ApiService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _ngxXml2jsonService: NgxXml2jsonService,
    private _timeGeoService: TimeGeoService
  ) {
    this.clientOptions = _dataService.clientOptions;
  }

  ngOnInit(): void {
    let udi = new UserDataInfo();
    udi.Date = new Date();
    udi.ClientOptions = this.clientOptions;
    udi.Path = "/mapaveiculos";

    this._apiService.setUserInfo(udi);

    this.loadmap();

    this._markers = [];
    this._polygon = [];
    this.managePositionsMap();

    this._route.params.subscribe((params) => {
      console.log("The id of this route is: ", params.options);

      if (params.options == "showFence") this._mostaCerca = true;
    });
  }

  loadmap() {
    if (!this.getConfig()) {
      return;
    }

    let zoom = this.clientOptions.Airport.DefaultZoom ?? 15;

    console.log("Map zoom setted: ", zoom);

    this._map = L.map("map").setView(
      [
        this.clientOptions.Airport.getLocationAirport().Latitude,
        this.clientOptions.Airport.getLocationAirport().Longitude,
      ],
      zoom
    );

    gtag("event", "mapa", {
      airport: this.clientOptions.Airport?.Sigla ?? "-",
    });

    var mb = L.tileLayer("https://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}", {
      minZoom: this.clientOptions.Airport.MinZoom ?? 13,
      maxZoom: this.clientOptions.Airport.MaxZoom ?? 16,
      subdomains: ["mt0", "mt1", "mt2", "mt3"],
    }).addTo(this._map);

    // var mb = L.tileLayer.mbTiles('assets/source/' + this.clientOptions.Airport.Sigla + '.mbtiles?v1', {
    //   minZoom: this.clientOptions.Airport.MinZoom ?? 13,
    //   maxZoom: this.clientOptions.Airport.MaxZoom ?? 16
    // }).addTo(this._map);

    // mb.on("databaseloaded", function (ev) {
    //   console.info("MBTiles DB loaded", ev);
    // });

    mb.on("databaseerror", function (ev) {
      console.info("MBTiles DB error", ev);
    });

    // A direção do mapa será oposta a escolha do cliente
    var dirMapa = this.getDirMapa();

    let dir = `${DirectionOptionEnum[dirMapa]}`;
    console.log(
      `Direction Mapa: ${DirectionOptionEnum[dirMapa]} / User:${
        DirectionOptionEnum[this.clientOptions.Direcion]
      }`
    );

    //this.puxaCerca();

    fetch(
      "assets/source/" +
        this.clientOptions.Airport.Sigla +
        (this.clientOptions.Airport.CurrentTerminal?.ChaveTerminal ?? false
          ? "-" + this.clientOptions.Airport.CurrentTerminal?.ChaveTerminal
          : "") +
        "-" +
        dir +
        ".kml" +
        "?v8"
    )
      .then((res) => res.text())
      .then((kmltext) => {
        // Create new kml overlay
        const parser = new DOMParser();
        const kml = parser.parseFromString(kmltext, "text/xml");
        const track = new L.KML(kml);
        this._map.addLayer(track);

        this.jsonKml = this._ngxXml2jsonService.xmlToJson(kml);
        //this.jsonKml = xlsToJsonParser.toJson(kmltext);
        // Adjust map to show the kml
        const bounds = track.getBounds();
        this._map.fitBounds(bounds);

        if (this.clientOptions.Airport.CustomPin) {
          this.clientOptions.Airport.CustomPin.forEach((i) => {
            let mk = L.marker([i.Point.Latitude, i.Point.Longitude], {
              icon: L.icon({
                iconUrl: i.Icon,
                iconSize: [48, 48],
                iconAnchor: [32, 64],
                popupAnchor: [-3, -76],
                //shadowUrl: 'my-icon-shadow.png',
                shadowSize: [68, 95],
                shadowAnchor: [22, 94],
              }),
            }).addTo(this._map);

            if (i.Text) {
              mk.bindPopup("<div>" + i.Text + "</div>", {
                autoPan: false,
                autoClose: false,
              }).openPopup();
            }
          });
        }
      });
  }

  //  puxaCerca() {
  //   //var wkx = require('wkx');
  //   //var buffer = require('buffer');

  //   // Sample data to convert
  //   var wkbLonlat = '0103000020E61000000100000005000000607B9279C89A37C0F3DBFF66863D47C0917538CA159B37C005DCFF2A683D47C01B94FE39D99A37C0CEDBFF24413D47C034C69C5A929A37C0BDDBFF605F3D47C0607B9279C89A37C0F3DBFF66863D47C0';

  //   // Split WKB into array of integers (necessary to turn it into buffer)
  //   var hexAry = wkbLonlat.match(/.{2}/g);
  //   var intAry = [];

  //   for (var i in hexAry) {
  //     intAry.push(parseInt(hexAry[i], 16));
  //   }

  //   // Generate the buffer
  //   var buf = Buffer.from(intAry);
  //   debugger;

  //   // Parse buffer into geometric object
  //   var geom:any = Geometry.parse(buf);

  //   // Should log '-73.700380647'
  //   console.log(geom.x);

  //   // Should log '40.739754168'
  //   console.log(geom.y);
  //  }

  voltar() {
    this._router.navigate(["/"]);
  }

  getDirMapa(): DirectionOptionEnum {
    let dirMapa = this.clientOptions.Direcion;
    switch (dirMapa) {
      case DirectionOptionEnum.CheckIn:
        dirMapa = DirectionOptionEnum.CheckOut;
        break;
      case DirectionOptionEnum.CheckOut:
        dirMapa = DirectionOptionEnum.CheckIn;
        break;
    }

    return dirMapa;
  }

  getConfig(): boolean {
    if (this.clientOptions == null || this.clientOptions.Airport == null) {
      this.clientOptions = this._dataService.getConfig();

      if (this.clientOptions == null || this.clientOptions.Airport == null) {
        this._router.navigate(["/"]);
        return false;
      }
    }
    return true;
  }

  managePositionsMap() {
    this.getLastPositions();

    setTimeout(() => {
      this.managePositionsMap();
    }, 10000);
  }

  getLastPositions() {
    // this._apiService.veiculos(this.clientOptions.Airport)
    // .subscribe(res => {

    //   console.log(res);

    //   // limpar markers anteriores

    //   // varrer novos e criar

    //   res.aData.forEach(element => {
    //     var marker = L.marker([element.latitude, element.longitude], {
    //       icon: L.icon({
    //         iconUrl: 'assets/images/van.png',
    //         iconSize: [32, 32],
    //         iconAnchor: [32, 16],
    //         popupAnchor: [-3, -76],
    //         //shadowUrl: 'my-icon-shadow.png',
    //         shadowSize: [68, 95],
    //         shadowAnchor: [22, 94]
    //       })
    //     })
    //     .addTo(this._map);
    //     this._markers[element.eqpt_serial] = element;
    //   });

    // });

    this._apiService.newVanList(this.clientOptions.Airport).subscribe((res) => {
      console.log(res);

      let rs = [];
      res.Veiculos.forEach((element) => {
        rs.push({
          placa: element.Placa,
          ignicao: element.Ignicao,
          UltimoEventoDataHora: element.getLastPosition()?.getDateFormated(),
          lastPointDescription: element.LastPoint?.Descricao,
        });
      });
      console.table(rs);

      // limpar markers anteriores
      for (var key in this._markers) {
        var element = this._markers[key];
        //console.log('Removendo ' + element.Placa);
        this._map.removeLayer(element.marker);
      }

      // limpar polygon anteriores
      for (var key in this._polygon) {
        var element = this._polygon[key];
        //console.log('Removendo ' + element.Placa);
        element.removeFrom(this._map);
      }

      // varrer novos e criar
      res.Veiculos.forEach((pv) => {
        // Cerca cosmética para visualização - comentar para publicação final
        // showFence
        if (this._mostaCerca) {
          var latlngs = [];

          pv.Cercas.forEach((c) => {
            c.posicoes.forEach((c1) => {
              latlngs.push([c1.Latitude, c1.Longitude]);
            });

            let p = L.polygon(latlngs, { color: "red", opacity: 0.08 });

            this._polygon.push(p);

            p.addTo(this._map);
            latlngs = [];
          });
        }
        // Cerca ===============================================================

        if (!pv.Ignicao) return;
        // remoção provisória para visualizar falhas do servidor
        if (pv.isValid()) {
          var dir;
          if (this.clientOptions.Airport.IgnoreDirection) {
            dir = DirectionOptionEnum.IgnoreDirection;
          } else {
            dir = pv.resumeDirecion(pv.getDirection(this.clientOptions));
          }

          if (
            (dir != this.getDirMapa() &&
              dir != DirectionOptionEnum.IgnoreDirection) ||
            dir == DirectionOptionEnum.NaoIdentificado
          ) {
            return;
          }

          pv.marker = L.marker(
            [pv.getLastPosition().latitude, pv.getLastPosition().longitude],
            {
              icon: L.icon({
                iconUrl: pv.getIcon(this.clientOptions),
                iconSize: [64, 64],
                iconAnchor: [32, 64],
                popupAnchor: [-3, -76],
                //shadowUrl: 'my-icon-shadow.png',
                shadowSize: [68, 95],
                shadowAnchor: [22, 94],
              }),
            }
          ).addTo(this._map);

          if (
            pv.getDirection(this.clientOptions) ==
              DirectionOptionEnum.ChegouEmbarque ||
            pv.getDirection(this.clientOptions) ==
              DirectionOptionEnum.ChegouDesembarque
          ) {
            pv.marker
              .bindPopup(
                pv.getDirection(this.clientOptions) ==
                  DirectionOptionEnum.ChegouEmbarque
                  ? "<div>Cheguei Embarque</div>" +
                      "<div>Placa : " +
                      pv.Placa +
                      "</div>"
                  : "<div>Desembarcando...</div>" +
                      "<div>Placa : " +
                      pv.Placa +
                      "</div>",
                {
                  autoPan: false,
                  autoClose: false,
                }
              )
              .openPopup();
          } else if (
            pv.getDirection(this.clientOptions) ==
              DirectionOptionEnum.ChegouAeroporto ||
            pv.getDirection(this.clientOptions) ==
              DirectionOptionEnum.ChegandoLocaliza
          ) {
            pv.marker
              .bindPopup(
                "<div>Cheguei</div>" + "<div>Placa : " + pv.Placa + "</div>",
                {
                  autoPan: false,
                  autoClose: false,
                }
              )
              .openPopup();
          } else {
            pv.marker
              .bindPopup(
                "<div>Placa " +
                  pv.Placa +
                  "</div>" +
                  // "<div>Ultima Posição " +
                  // pv.getLastPosition().getDateFormated() +
                  // "</div>" +
                  //'<div>Direção: ' + pv.getMessage(pv.getDirection(this.clientOptions)) + '</div>'+
                  "<div>Tempo Estimado: " +
                  this._timeGeoService.getTempoEstimadoByKml(
                    pv,
                    this.clientOptions,
                    this.jsonKml,
                    true
                  ) +
                  " </div>" +
                  "<div></div>",
                { autoPan: false, autoClose: false }
              )
              .openPopup();
          }

          this._markers[pv.NumeroSerie] = pv;
        }
      });
    });
  }
}
